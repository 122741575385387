<template>
    <div class=container>
        <tbody><tr>
            <td><div align="center"><font size="+2">Direct-Global Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br></td>
          </tr>
          <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="47%"><strong>Diffuse V-Grooves : Concave and Convex</strong></td>
                      <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Groove_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Groove_Scene.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Scene</strong></div></td>
                      <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Groove_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Groove_Direct.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Direct</strong></div></td>
                      <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Groove_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Groove_Global.jpg" width="202" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Global</strong></div></td>
                    </tr>
                  </tbody></table>
                    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td>This scene includes two identical V-grooves, except that the left one  is concave and the right one is convex. The two sides of each groove  are made of matte paper (white on left side and pink on the right). As  expected, the convex groove has no global illumination while the  concave one has a strong global component due to diffuse  interreflections that increase toward the edge of the groove. The  interreflections cause the color of one side to "bleed" into the other.  Several studies have been conducted in psychophysics that show that  interreflections play an important role in the perception of shape and  color.</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                  </tbody></table></td>
              </tr>

            </tbody></table></td>
          </tr>
          
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Mirror Ball : Failure Case</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Mirror_Ball_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Mirror_Ball_Scene.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Mirror_Ball_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Mirror_Ball_Direct.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Mirror_Ball_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Mirror_Ball_Global.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>This  scene with a mirror sphere surrounded by three diffuse walls violates  the assumption that the global component received by scene points is  smooth compared to the high frequency illumination. As a result, we see  a regular pattern (artifacts) due to over-estimation of the global  component and under-estimation of the direct component. Looking at it  in a different way, at each of these corrupted pixels there is no  longer a single significant scattering event but rather two or more.  Also note the checker-like pattern that appears on the mirror sphere  itself in the direct and global images.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody>
    </div>

</template>

<script>
export default {
}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
.title{
    text-align: center;
}
.table{
    border:1;
    border-collapse:0;
    border-color: black;
}
</style>